import React, { Component, lazy, Suspense  } from 'react';
import './App.css';
import './components/error_handler.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Breadcrumbs from './components/breadcrumbs';
import PopularPost from './components/popular_post.jsx'
const Home = lazy(() => import('./components/home.jsx'));
const Sidebar = lazy(() => import('./components/sidebar.jsx'));
const Footer = lazy(() => import('./components/footer.jsx'));
const Tentang = lazy(() => import('./components/tentang.jsx'));
const Disclaimer = lazy(() => import('./components/disclaimer.jsx'));
const PrivacyPolicy = lazy(() => import('./components/privacy_policy.jsx'));
const ContactUs = lazy(() => import('./components/contact_us.jsx'));
const AboutUs = lazy(() => import('./components/about_us.jsx'));


const MainCi4 = lazy(() => import('./components/folder_codeigniter_4/main_ci4.jsx'));
const Persiapanci4 = lazy(() => import('./components/folder_codeigniter_4/persiapan_ci4.jsx'));
const Instalasici4 = lazy(() => import('./components/folder_codeigniter_4/instalasi_ci4.jsx'));
const Apa_itu_codeigniter = lazy(() => import('./components/folder_codeigniter_4/apa_itu_codeigniter.jsx'));
const Menjalankan_codeIgniter_4_dengan_gitbash = lazy(() => import('./components/folder_codeigniter_4/menjalankan_ci4_dg_gitbash.jsx'));
const pengenalan_dasar_Ci4 = lazy(() => import('./components/folder_codeigniter_4/pengenalan_dasar_Ci4.jsx'));
const CaraMembuatHalamanPadaci4 = lazy(() => import('./components/folder_codeigniter_4/cara_membuat_halaman_ci4.jsx'));
const MengeditTampilanHalamanCi4 = lazy(() => import('./components/folder_codeigniter_4/mengedit_tampilan_halaman_ci4.jsx'));
const CaraCepatMembukaVisualStudioCodeUntukEditingProjectCodeIgniter4 = lazy(() => import('./components/folder_codeigniter_4/cara_cepat_membuka_project_ci4_menggunakan_vscode.jsx'));

const MainUnity = lazy(() => import('./components/folder_unity/main_unity.jsx'));
const ApaItuUnity = lazy(() => import('./components/folder_unity/apa_itu_unity.jsx'));
const CaraInstallUnityHubDanMendapatkanLisensiGratisUnity3D = lazy(() => import('./components/folder_unity/cara_install_unity_hub_dan_mendapatkan_lisensi_gratis_unity_3D.jsx'));
const CaraInstallUnity3DMenggunakanUnityHub = lazy(() => import('./components/folder_unity/cara_install_unity_3D_menggunakan_unity_hub.jsx'));
const CaraMembuatGameAndroidMenggunakanUnity3DUntukPemulaHelloWorld = lazy(() => import('./components/folder_unity/cara_membuat_game_android_menggunakan_unity_3D_untuk_pemula_hello_world.jsx'));
const CaramenggantiKodeEditorYangAkanDigunakanPadaUnity3D = lazy(() => import('./components/folder_unity/cara_mengganti_kode_editor_yang_akan_digunakan_pada_unity_3D.jsx'));
const BagianBagianUnity3D = lazy(() => import('./components/folder_unity/bagian_bagian_unity_3D.jsx'));
const CaraMeningkatkanScoreBerdasarkanWaktuUnity3D = lazy(() => import('./components/folder_unity/cara_meningkatkan_score_berdasarkan_waktu_di_unity_3D.jsx'));
const CaraMeningkatkanScoreBerdasarkanJarakUnity3D = lazy(() => import('./components/folder_unity/cara_meningkatkan_score_berdasarkan_jarak_di_unity_3D.jsx'));
const CaraAksesScriptDariEditorPadaInspectorUnity3D = lazy(() => import('./components/folder_unity/cara_akses_script_dari_editor_pada_inspector_unity_3D.jsx'));
const MengenalCanvasPadaUnity3D = lazy(() => import('./components/folder_unity/mengenal_canvas_pada_unity_3D.jsx'));
const TataLetakDasarUnity3D = lazy(() => import('./components/folder_unity/tata_letak_dasar_unity_3D.jsx'));
const KomponenVisualUnity3D = lazy(() => import('./components/folder_unity/komponen_visual_unity_3D.jsx'));
const KomponenInteraksiUnity3D = lazy(() => import('./components/folder_unity/komponen_interaksi_unity_3D.jsx'));
const IntegrasiAnimasiUnity3D = lazy(() => import('./components/folder_unity/integrasi_animasi_unity_3D.jsx'));
const ApaItuUnityHub = lazy(() => import('./components/folder_unity/apa_itu_unity_hub.jsx'));
const TataLetakOtomatisUnity3D = lazy(() => import('./components/folder_unity/tata_letak_otomatis.jsx'));
const RichTextUnity3D = lazy(() => import('./components/folder_unity/rich_text_unity_3D.jsx'));
const EventSystemUnity3D = lazy(() => import('./components/folder_unity/event_system_unity_3D.jsx'));
const MessagingSystemUnity3D = lazy(() => import('./components/folder_unity/messaging_system_unity_3D.jsx'));
const InputModulesUnity3D = lazy(() => import('./components/folder_unity/input_modules_unity_3D.jsx'));
const SupportedEventsUnity3D = lazy(() => import('./components/folder_unity/supported_events_unity_3D.jsx'));
const RaycastersUnity3D = lazy(() => import('./components/folder_unity/raycasters_unity_3D.jsx'));
// const RectTransformUnity3D = lazy(() => import('./components/folder_unity/rect_transform_unity_3D.jsx'));
const ErrorBrokenPipeUniy3D = lazy(() => import('./components/folder_unity/mengatasi_error_broken_pipe_unity_3D.jsx'));
const MembuatGameObjectBerputarUnity3D = lazy(() => import('./components/folder_unity/cara_membuat_game_object_berputar_unity_3D.jsx'));

const MainCorelDraw = lazy(() => import('./components/folder_coreldraw/main_coreldraw.jsx'));
const ApaItuCorelDraw = lazy(() => import('./components/folder_coreldraw/apa_itu_coreldraw.jsx'));
const MengenalBagianBagianCorelDrawX6 = lazy(() => import('./components/folder_coreldraw/mengenal_bagian_bagian_corel_draw_x6.jsx'));
const MerubahBahasaCorelDrawX6 = lazy(() => import('./components/folder_coreldraw/merubah_bahasa_coreldraw_x6.jsx'));
const InstallCorelDrawX6 = lazy(() => import('./components/folder_coreldraw/install_corel_draw_x6.jsx'));
const MengubahpengaturanStartupCorelDrawX6 = lazy(() => import('./components/folder_coreldraw/mengubah_pengaturan_startup_corel_draw_x6.jsx'));

const MainMicrosoftOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/main_microsoft_office_excel_2010.jsx'));
const CaraMenjalankanMicrosoftOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_menjalankan_microsoft_office_excel_2010.jsx'));
const BagianBagianMicrosoftOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/bagian_bagian_microsoft_office_excel_2010.jsx'));
const MemindahkanSelAktifMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_memindahkan_sel_aktif_ms_office_excel_2010.jsx'));
const MacamKlikDanBentukDariPointerMouse = lazy(() => import('./components/folder_msoffice_excel2010/macam_klik_dan_bentuk_dari_pointer_mouse.jsx'));
const MenyimpanDokumenMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/menyimpan_dokumen_ms_office_excel_2010.jsx'));
const MembukaDokumenMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuka_dokumen_hasil_penyimpanan_ms_office_excel_2010.jsx'));
const MenutupDokumenMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_menutup_dokumen_ms_office_excel_2010.jsx'));
const WorksheetMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/worksheet_ms_office_excel_2010.jsx'));
const CaraMemilihWorksheetMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_memilih_sheet_ms_office_excel_2010.jsx'));
const CaraMengteditSheetMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_mengtedit_sheet_ms_office_excel_2010.jsx'));
const CaraMenentukanUkuranTampilanSheetMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_menentukan ukuran_tampilan_sheet_ms_office_excel_2010.jsx'));
const CaraInputDataPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_input_data_pada_ms_office_excel_2010.jsx'));
const MengenalNamaAlamatSelPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_nama_alamat_sel_pada_ms_office_excel_2010.jsx'));
const CaraMembuatNomorUrutPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_nomor_urut_pada_ms_office_excel_2010.jsx'));
const CaraMembuatDataTanggalBerurutanPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_data_tanggal_berurutan_pada_ms_office_excel_2010.jsx'));
const CaraMembuatCustomListPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_custom_list_pada_ms_office_excel_2010.jsx'));
const CaraMemperbaikiKesalahanPengetikanDiDalamSelPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_memperbaiki_kesalahan_pengetikan_didalam_sel_pada_ms_office_excel_2010.jsx'));
const CaraMenghapusMengcopyMemindahkanDataPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_menghapus_mengcopy_memindahkan_data_pada_ms_office_excel_2010.jsx'));
const CaraMengulangiDanMembatalkanPerintahPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_mengulangi_dan_membatalkan_perintah_pada_ms_office_excel_2010.jsx'));
const CaraMemilihAtauMenyeleksiSelSecaraAcakPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_memilih_sel_secara_acak_pada_ms_office_excel_2010.jsx'));
const CaraMemilihAtauMenyeleksiBarisAtauKolomPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_memilih_atau_menyeleksi_baris_atau_kolom_pada_ms_office_excel_2010.jsx'));
const CaraMelakukanPengaturanPadaKolomPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_melakukan_pengaturan_pada_kolom_pada_ms_office_excel_2010.jsx'));
const CaraMelakukanPengaturanPadaBarisPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_melakukan_pengaturan_pada_baris_pada_ms_office_excel_2010.jsx'));
const CaraMenggabungkanSelBarisKolomPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_menggabungkan_sel_baris_kolom_pada_ms_office_excel_2010.jsx'));
const CaraMembuatHurufMenjadiTebalMiringGarisBawahPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_huruf_menjadi_tebal_miring_garis_bawah_pada_ms_office_excel_2010.jsx'));
const CaraMengaturPerataanTeksPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_mengatur_perataan_teks_pada_ms_office_excel_2010.jsx'));
const CaraMengaturFormatPerataanTeksBeberapaSelPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_mengatur_Format_perataan_teks_beberapa_sel_pada_ms_office_excel_2010.jsx'));
const CaraMengaturOrientasiDataAtauTeksDalamSelPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_mengatur_orientasi_data_atau_teks_dalam_sel_pada_ms_office_excel_2010.jsx'));
const CaraMengaturFormatFontDanUkuranFontPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_mengatur_format_font_dan_ukuran_font_pada_ms_office_excel_2010.jsx'));
const CaraMembuatPengaturanBorderPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_pengaturan_border_pada_ms_office_excel_2010.jsx'));
const CaraMerubahWarnaBackgroundPadaSelPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_merubah_warna_background_pada_sel_pada_ms_office_excel_2010.jsx'));
const TipsCaraCepatMembuatBorderPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/tips_cara_cepat_membuat_border_pada_ms_office_excel_2010.jsx'));
const CaraMenghapusBorderPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_menghapus_border_pada_ms_office_excel_2010.jsx'));
const CaraMembuatFormatKomaPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_format_koma_pada_ms_office_excel_2010.jsx'));
const CaraMembuatFormatMataUangPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_format_mata_uang_pada_ms_office_excel_2010.jsx'));
const CaraMembuatFormatTeksDenganStylePadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/cara_membuat_format_teks_dengan_style_pada_ms_office_excel_2010.jsx'));
const PengertianFormulaAtauRumusPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/pengertian_formula_atau_rumus_pada_ms_office_excel_2010.jsx'));
const FungsiDariOperatorFormulaAtauRumusPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/fungsi_dari_operator_formula_atau_rumus_pada_ms_office_excel_2010.jsx'));
const ApaItuStrukturFungsiPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/apa_itu_struktur_fungsi_pada_ms_office_excel_2010.jsx'));
const MengenalFungsiLogikaPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_logika_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiLogikaPadaMsOfficeExcel2010FungsiIf = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_logika_pada_ms_office_excel_2010_fungsi_if.jsx'));
  const MengenalFungsiLogikaPadaMsOfficeExcel2010FungsiIfBercabang = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_logika_pada_ms_office_excel_2010_fungsi_if_bercabang.jsx'));
const MengenalFungsiTanggalPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDate = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_date.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDateValue = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_datevalue.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDay = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_day.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDays360 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_days360.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiMonth = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_month.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiWeekday = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_weekday.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiYear = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_year.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiNow = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_now.jsx'));
  const MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiToday = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_tanggal_pada_ms_office_excel_2010_fungsi_today.jsx'));
const MengenalFungsiJamPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_jam_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiJamPadaMsOfficeExcel2010FungsiTime = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_jam_pada_ms_office_excel_2010_fungsi_time.jsx'));
  const MengenalFungsiJamPadaMsOfficeExcel2010FungsiTimeValue = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_jam_pada_ms_office_excel_2010_fungsi_timevalue.jsx'));
  const MengenalFungsiJamPadaMsOfficeExcel2010FungsiHour = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_jam_pada_ms_office_excel_2010_fungsi_hour.jsx'));
  const MengenalFungsiJamPadaMsOfficeExcel2010FungsiMinute = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_jam_pada_ms_office_excel_2010_fungsi_minute.jsx'));
  const MengenalFungsiJamPadaMsOfficeExcel2010FungsiSecond = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_jam_pada_ms_office_excel_2010_fungsi_second.jsx'));
const MengenalFungsiTeksPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiChar = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_char.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiCode = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_code.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiLeft = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_left.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiMid = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_mid.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiRight = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_right.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiLen = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_len.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiConcatenate = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_concatenate.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiLower = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_lower.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiUpper = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_upper.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiProper = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_proper.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiRept = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_rept.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiText = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_text.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiT = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_t.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiValue = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_value.jsx'));
  const MengenalFungsiTeksPadaMsOfficeExcel2010FungsiFind = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_teks_pada_ms_office_excel_2010_fungsi_find.jsx'));
const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiAbs = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_abs.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiInt = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_int.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiMod = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_mod.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiProduct = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_product.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiRound = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_round.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiAbsRoundDown = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_rounddown.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiAbsRoundUp = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_roundup.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiSum = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_sum.jsx'));
  const MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiSumIf = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_matematika_dan_fungsi_trigonometri_pada_ms_office_excel_2010_fungsi_sumif.jsx'));
const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiAverage = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_average.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiAverageIf = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_averageif.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCount = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_count.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCounta = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_counta.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCountBlank = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_countblank.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCountIf = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_countif.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMax = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_max.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMedian = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_median.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMin = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_min.jsx'));
  const MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMode = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_operasi_untuk_statistik_pada_ms_office_excel_2010_fungsi_mode.jsx'));
const MengenalFungsiLookupPadaMicrosoftOfficeExcel2010 = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_lookup_pada_ms_office_excel_2010.jsx'));
  const MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiHLookup = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_lookup_pada_ms_office_excel_2010_fungsi_hlookup.jsx'));
  const MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiVLookup = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_lookup_pada_ms_office_excel_2010_fungsi_vlookup.jsx'));
  const MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiLookup = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_lookup_pada_ms_office_excel_2010_fungsi_lookup.jsx'));
  const MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiMatch = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_lookup_pada_ms_office_excel_2010_fungsi_match.jsx'));
  const MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiChoose = lazy(() => import('./components/folder_msoffice_excel2010/mengenal_fungsi_lookup_pada_ms_office_excel_2010_fungsi_choose.jsx'));

const MainSeputarAplikasiAndroid = lazy(() => import('./components/folder_seputar_aplikasi_android/main_seputar_aplikasi_android.jsx'));
const CaraMembuatHurufAtauTulisanPadaWhatsAppMenjadiTebal = lazy(() => import('./components/folder_seputar_aplikasi_android/cara_membuat_huruf_atau_tulisan_pada_whatsapp_menjadi_tebal.jsx'));
const MembuatHurufAtauTulisanPadaWhatsAppMenjadiMiring = lazy(() => import('./components/folder_seputar_aplikasi_android/membuat_huruf_atau_tulisan_pada_whatsapp_menjadi_miring.jsx'));
const MembuatHurufAtauTulisanPadaWhatsAppMenjadiCoretan = lazy(() => import('./components/folder_seputar_aplikasi_android/membuat_huruf_atau_tulisan_pada_whatsapp_menjadi_coretan.jsx'));
const MembuatHurufAtauTulisanPadaWhatsAppMenjadiMonospace = lazy(() => import('./components/folder_seputar_aplikasi_android/membuat_huruf_atau_tulisan_pada_whatsapp_menjadi_monospace.jsx'));
const TutorialKirimPesanWhatsAppTanpaSimpanNomor = lazy(() => import('./components/folder_seputar_aplikasi_android/tutorial_kirim_pesan_whatsapp_tanpa_simpan_nomor.jsx'));
const CaraMengetahuiPesanSudahTerbacaWalaupunTandaTerbacaDinonaktifkanPenerima = lazy(() => import('./components/folder_seputar_aplikasi_android/cara_mengetahui_pesan_sudah_terbaca_walaupun_tanda_terbaca_dinonaktifkan_penerima.jsx'));
const CaraMenghematKuotaInternetSaatMenggunakanAplikasiZoom = lazy(() => import('./components/folder_seputar_aplikasi_android/cara_menghemat_kuota_internet_saat_menggunakan_aplikasi_zoom.jsx'));
const CaraCekKuotaInternet = lazy(() => import('./components/folder_seputar_aplikasi_android/cara_cek_kuota_internet.jsx'));

const MainPengetahuanUmum = lazy(() => import('./components/folder_pengetahuan_umum/main_pengetahuan_umum.jsx'))
const KodeTransferBankDiIndonesia = lazy(() => import('./components/folder_pengetahuan_umum/kode_transfer_bank_di_indonesia.jsx'))
const JurnalKerja = lazy(() => import('./components/folder_pengetahuan_umum/jurnal_kerja.jsx'))


// awal error handle
const renderLoader = () =>
<div  style={{backgroundColor: 'white'}} >
      <div className="loader-container">
        <div className="loader"></div>
      </div>
      </div>

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return <div className="footers" style={{backgroundColor: '#ff0000'}} >
    
      {/* <ColoredLine color="gold" />            */}
      {/* <br/>       */}
      <div  style={{WebkitTextFillColor:'#000000', fontFamily: 'Georgia'}}>Loading failed!
      <br/> Please reload...!!
      </div>
    </div>;
    }

    return this.props.children;
  }
}
// akhir error handle



///////////awal firebase sdk///////////
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuSgKNrC60MfATMvc-4dAUyRZKg-8FVuo",
  authDomain: "web-dictionary-plus-plus.firebaseapp.com",
  databaseURL: "https://web-dictionary-plus-plus-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "web-dictionary-plus-plus",
  storageBucket: "web-dictionary-plus-plus.appspot.com",
  messagingSenderId: "252129763762",
  appId: "1:252129763762:web:b22df74f7e1a5400ac44ab",
  measurementId: "G-JSRKW90567"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
/////////akhir firebase sdk////////////




class App extends Component {
  
  render()  {
    
    return (

      <ErrorBoundary>

      <Suspense fallback={renderLoader()}>
      
      <Router>
      <Sidebar/>
        <div id="colorlib-page">
        
          <div id="container-wrap">    
            

       
            
            
            
            <div id="colorlib-main">
            
              <Breadcrumbs/>            
              
              <Switch>                    
               
                <Route exact path="/"  component={Home} />

                <Router>

                
                
                 
                  <Switch>
                      <Route exact   path="/codeIgniter-4"  component={MainCi4} />                      
                      <Route exact   path="/unity-3D"  component={MainUnity} />
                      <Route exact path="/corel-draw" component={MainCorelDraw}/>                    
                      <Route exact path="/microsoft-office-excel-2010" component={MainMicrosoftOfficeExcel2010}/>                    
                      <Route exact path="/seputar-aplikasi-android" component={MainSeputarAplikasiAndroid}/>
                      <Route exact path="/pengetahuan-umum" component={MainPengetahuanUmum}/>
                      <Route exact path="/disclaimer" component={Disclaimer}/>
                      <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                      <Route exact path="/about" component={AboutUs}/>
                      <Route exact path="/contact" component={ContactUs}/>
                      
                     
                      <Router>
                        <Route exact path="/codeIgniter-4/apa-itu-codeigniter"  component={Apa_itu_codeigniter} />
                        <Route exact path="/codeIgniter-4/persiapan_ci4"  component={Persiapanci4} />
                        <Route exact path="/codeIgniter-4/instalasi_ci4"  component={Instalasici4} />
                        <Route exact path="/codeIgniter-4/menjalankan_ci4"  component={Menjalankan_codeIgniter_4_dengan_gitbash} />
                        <Route exact path="/codeIgniter-4/pengenalan-dasar-ci4"  component={pengenalan_dasar_Ci4} />
                        <Route exact path="/codeIgniter-4/cara-membuat-halaman-codeigniter-4"  component={CaraMembuatHalamanPadaci4} />
                        <Route exact path="/codeIgniter-4/mengedit-tampilan-halaman-codeigniter-4"  component={MengeditTampilanHalamanCi4} />
                        <Route exact path="/codeIgniter-4/cara-cepat-membuka-vscode-untuk-editing-project-codeigniter-4"  component={CaraCepatMembukaVisualStudioCodeUntukEditingProjectCodeIgniter4} />

                        <Route exact path="/unity-3D/apa-itu-game-engine-unity-3D" component={ApaItuUnity}/>
                        <Route exact path="/unity-3D/cara-install-unity-hub-dan-mendapatkan-lisensi-gratis-unity-3D" component={CaraInstallUnityHubDanMendapatkanLisensiGratisUnity3D}/>
                        <Route exact path="/unity-3D/cara-install-unity-3D-menggunakan-unity-hub" component={CaraInstallUnity3DMenggunakanUnityHub}/>
                        <Route exact path="/unity-3D/cara-membuat-game-android-menggunakan-unity-3D-untuk-pemula-hello-world" component={CaraMembuatGameAndroidMenggunakanUnity3DUntukPemulaHelloWorld}/>
                        <Route exact path="/unity-3D/cara-mengganti-kode-editor-yang-akan-digunakan-pada-unity-3D" component={CaramenggantiKodeEditorYangAkanDigunakanPadaUnity3D}/>
                        <Route exact path="/unity-3D/bagian-bagian-unity-3D" component={BagianBagianUnity3D}/>
                        <Route exact path="/unity-3D/cara-meningkatkan-score-berdasarkan-waktu-di-Unity-3D" component={CaraMeningkatkanScoreBerdasarkanWaktuUnity3D}/>
                        <Route exact path="/unity-3D/cara-meningkatkan-score-berdasarkan-jarak-di-Unity-3D" component={CaraMeningkatkanScoreBerdasarkanJarakUnity3D}/>
                        <Route exact path="/unity-3D/cara-akses-script-dari-editor-pada-inspector-unity-3D" component={CaraAksesScriptDariEditorPadaInspectorUnity3D}/>
                        <Route exact path="/unity-3D/mengenal-canvas-pada-unity-3D" component={MengenalCanvasPadaUnity3D}/>
                        <Route exact path="/unity-3D/tata-letak-dasar-unity-3D" component={TataLetakDasarUnity3D}/>
                        <Route exact path="/unity-3D/komponen-visual-unity-3D" component={KomponenVisualUnity3D}/>
                        <Route exact path="/unity-3D/komponen-interaksi-unity-3D" component={KomponenInteraksiUnity3D}/>
                        <Route exact path="/unity-3D/integrasi-animasi-unity-3D" component={IntegrasiAnimasiUnity3D}/>
                        <Route exact path="/unity-3D/apa-itu-unity-hub" component={ApaItuUnityHub}/>
                        <Route exact path="/unity-3D/tata-letak-otomatis-unity-3D" component={TataLetakOtomatisUnity3D}/>
                        <Route exact path="/unity-3D/rich-text-unity-3D" component={RichTextUnity3D}/>
                        <Route exact path="/unity-3D/event-system-unity-3D" component={EventSystemUnity3D}/>
                        <Route exact path="/unity-3D/messaging-system-unity-3D" component={MessagingSystemUnity3D}/>
                        <Route exact path="/unity-3D/input-modules-unity-3D" component={InputModulesUnity3D}/>
                        <Route exact path="/unity-3D/supported-events-unity-3D" component={SupportedEventsUnity3D}/>
                        <Route exact path="/unity-3D/raycasters-unity-3D" component={RaycastersUnity3D}/>
                        <Route exact path="/unity-3D/mengatasi-error-broken-pipe-unity-3D" component={ErrorBrokenPipeUniy3D}/>
                        <Route exact path="/unity-3D/membuat-game-object-berputar-unity-3D" component={MembuatGameObjectBerputarUnity3D}/>

                        <Route exact path="/corel-draw/apa-itu-corel-draw" component={ApaItuCorelDraw}/>
                        <Route exact path="/corel-draw/mengenal-bagian-bagian-corel-draw-x6" component={MengenalBagianBagianCorelDrawX6}/>
                        <Route exact path="/corel-draw/merubah-bahasa-corel-draw-x6" component={MerubahBahasaCorelDrawX6}/>
                        <Route exact path="/corel-draw/install-corel-draw-x6" component={InstallCorelDrawX6}/>
                        <Route exact path="/corel-draw/mengubah-pengaturan-startup-corel-draw-x6" component={MengubahpengaturanStartupCorelDrawX6}/>

                        <Route exact path="/microsoft-office-excel-2010/cara-menjalankan-microsoft-office-excel-2010" component={CaraMenjalankanMicrosoftOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-bagian-microsoft-office-excel-2010" component={BagianBagianMicrosoftOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-memindahkan-sel-aktif-microsoft-office-excel-2010" component={MemindahkanSelAktifMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/macam-klik-dan-bentuk-dari-pointer-mouse" component={MacamKlikDanBentukDariPointerMouse}/>
                        <Route exact path="/microsoft-office-excel-2010/menyimpan-dokumen-ms-office-excel-2010" component={MenyimpanDokumenMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuka-dokumen-ms-office-excel-2010" component={MembukaDokumenMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-menutup-dokumen-ms-office-excel-2010" component={MenutupDokumenMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/worksheet-ms-office-excel-2010" component={WorksheetMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-memilih-worksheet-ms-office-excel-2010" component={CaraMemilihWorksheetMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-mengedit-sheet-ms-office-excel-2010" component={CaraMengteditSheetMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-menentukan-ukuran-tampilan-sheet-ms-office-excel-2010" component={CaraMenentukanUkuranTampilanSheetMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-input-data-pada-ms-office-excel-2010" component={CaraInputDataPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-nama-alamat-sel-pada-ms-office-excel-2010" component={MengenalNamaAlamatSelPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-nomor-urut-pada-ms-office-excel-2010" component={CaraMembuatNomorUrutPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-data-tanggal-berurutan-pada-ms-office-excel-2010" component={CaraMembuatDataTanggalBerurutanPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-custom-list-pada-ms-office-excel-2010" component={CaraMembuatCustomListPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-memperbaiki-kesalahan-pengetikan-didalam-sel-pada-ms-office-excel-2010" component={CaraMemperbaikiKesalahanPengetikanDiDalamSelPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-menghapus-mengcopy-memindahkan-data-pada-ms-office-excel-2010" component={CaraMenghapusMengcopyMemindahkanDataPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-mengulangi-dan-membatalkan-perintah-pada-ms-office-excel-2010" component={CaraMengulangiDanMembatalkanPerintahPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-memilih-sel-secara-acak-pada-ms-office-excel-2010" component={CaraMemilihAtauMenyeleksiSelSecaraAcakPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-memilih-atau-menyeleksi-baris-atau-kolom-pada-ms-office-excel-2010" component={CaraMemilihAtauMenyeleksiBarisAtauKolomPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-melakukan-pengaturan-pada-kolom-pada-ms-office-excel-2010" component={CaraMelakukanPengaturanPadaKolomPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-melakukan-pengaturan-pada-baris-pada-ms-office-excel-2010" component={CaraMelakukanPengaturanPadaBarisPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-menggabungkan-sel-baris-kolom-pada-ms-office-excel-2010" component={CaraMenggabungkanSelBarisKolomPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-huruf-menjadi-tebal-miring-garis-bawah-pada-ms-office-excel-2010" component={CaraMembuatHurufMenjadiTebalMiringGarisBawahPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-mengatur-perataan-teks-pada-ms-office-excel-2010" component={CaraMengaturPerataanTeksPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-mengatur-Format-perataan-teks-beberapa-sel-pada-ms-office-excel-2010" component={CaraMengaturFormatPerataanTeksBeberapaSelPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-mengatur-orientasi-data-atau-teks-dalam-sel-pada-ms-office-excel-2010" component={CaraMengaturOrientasiDataAtauTeksDalamSelPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-mengatur-format-font-dan-ukuran-font-pada-ms-office-excel-2010" component={CaraMengaturFormatFontDanUkuranFontPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-pengaturan-border-pada-ms-office-excel-2010" component={CaraMembuatPengaturanBorderPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-merubah-warna-background-pada-sel-pada-ms-office-excel-2010" component={CaraMerubahWarnaBackgroundPadaSelPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/tips-cara-cepat-membuat-border-pada-ms-office-excel-2010" component={TipsCaraCepatMembuatBorderPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-menghapus-border-pada-ms-office-excel-2010" component={CaraMenghapusBorderPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-format-koma-pada-ms-office-excel-2010" component={CaraMembuatFormatKomaPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-format-mata-uang-pada-ms-office-excel-2010" component={CaraMembuatFormatMataUangPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/cara-membuat-format-teks-dengan-style-pada-ms-office-excel-2010" component={CaraMembuatFormatTeksDenganStylePadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/pengertian-formula-atau-rumus-pada-ms-office-excel-2010" component={PengertianFormulaAtauRumusPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/fungsi-dari-operator-formula-atau-rumus-pada-ms-office-excel-2010" component={FungsiDariOperatorFormulaAtauRumusPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/apa-itu-struktur-fungsi-pada-ms-office-excel-2010" component={ApaItuStrukturFungsiPadaMsOfficeExcel2010}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-logika-pada-ms-office-excel-2010" component={MengenalFungsiLogikaPadaMsOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-logika-pada-ms-office-excel-2010/fungsi-if" component={MengenalFungsiLogikaPadaMsOfficeExcel2010FungsiIf}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-logika-pada-ms-office-excel-2010/fungsi-if-bercabang" component={MengenalFungsiLogikaPadaMsOfficeExcel2010FungsiIfBercabang}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010" component={MengenalFungsiTanggalPadaMsOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-date" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDate}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-datevalue" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDateValue}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-day" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDay}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-days360" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiDays360}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-month" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiMonth}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-weekday" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiWeekday}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-year" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiYear}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-now" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiNow}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-tanggal-pada-ms-office-excel-2010/fungsi-today" component={MengenalFungsiTanggalPadaMsOfficeExcel2010FungsiToday}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-jam-pada-ms-office-excel-2010" component={MengenalFungsiJamPadaMsOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-jam-pada-ms-office-excel-2010/fungsi-time" component={MengenalFungsiJamPadaMsOfficeExcel2010FungsiTime}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-jam-pada-ms-office-excel-2010/fungsi-timevalue" component={MengenalFungsiJamPadaMsOfficeExcel2010FungsiTimeValue}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-jam-pada-ms-office-excel-2010/fungsi-hour" component={MengenalFungsiJamPadaMsOfficeExcel2010FungsiHour}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-jam-pada-ms-office-excel-2010/fungsi-minute" component={MengenalFungsiJamPadaMsOfficeExcel2010FungsiMinute}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-jam-pada-ms-office-excel-2010/fungsi-second" component={MengenalFungsiJamPadaMsOfficeExcel2010FungsiSecond}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010" component={MengenalFungsiTeksPadaMsOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-char" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiChar}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-code" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiCode}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-left" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiLeft}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-mid" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiMid}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-right" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiRight}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-len" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiLen}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-concatenate" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiConcatenate}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-lower" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiLower}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-upper" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiUpper}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-proper" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiProper}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-rept" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiRept}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-text" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiText}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-t" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiT}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-value" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiValue}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-teks-pada-ms-office-excel-2010/fungsi-find" component={MengenalFungsiTeksPadaMsOfficeExcel2010FungsiFind}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-abs" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiAbs}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-int" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiInt}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-mod" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiMod}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-product" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiProduct}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-round" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiRound}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-rounddown" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiAbsRoundDown}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-roundup" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiAbsRoundUp}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-sum" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiSum}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-matematika-dan-fungsi-trigonometri-pada-ms-office-excel-2010/fungsi-sumif" component={MengenalFungsiMatematikaDanFungsiTrigonometriPadaMsOfficeExcel2010FungsiSumIf}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-average" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiAverage}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-averageif" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiAverageIf}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-count" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCount}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-counta" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCounta}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-countblank" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCountBlank}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-countif" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiCountIf}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-max" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMax}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-median" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMedian}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-min" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMin}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010/fungsi-mode" component={MengenalFungsiOperasiUntukStatistikPadaMicrosoftOfficeExcel2010FungsiMode}/>
                        <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-lookup-dan-reference-pada-ms-office-excel-2010" component={MengenalFungsiLookupPadaMicrosoftOfficeExcel2010}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-lookup-dan-reference-pada-ms-office-excel-2010/fungsi-hlookup" component={MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiHLookup}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-lookup-dan-reference-pada-ms-office-excel-2010/fungsi-vlookup" component={MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiVLookup}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-lookup-dan-reference-pada-ms-office-excel-2010/fungsi-lookup" component={MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiLookup}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-lookup-dan-reference-pada-ms-office-excel-2010/fungsi-match" component={MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiMatch}/>
                          <Route exact path="/microsoft-office-excel-2010/mengenal-fungsi-lookup-dan-reference-pada-ms-office-excel-2010/fungsi-choose" component={MengenalFungsiLookupPadaMicrosoftOfficeExcel2010FungsiChoose}/>

                        <Route exact path="/seputar-aplikasi-android/cara-membuat-huruf-atau-tulisan-pada-whatsapp-menjadi-tebal" component={CaraMembuatHurufAtauTulisanPadaWhatsAppMenjadiTebal}/>
                        <Route exact path="/seputar-aplikasi-android/membuat-huruf-atau-tulisan-pada-whatsapp-menjadi-miring" component={MembuatHurufAtauTulisanPadaWhatsAppMenjadiMiring}/>
                        <Route exact path="/seputar-aplikasi-android/membuat-huruf-atau-tulisan-pada-whatsapp-menjadi-coretan" component={MembuatHurufAtauTulisanPadaWhatsAppMenjadiCoretan}/>
                        <Route exact path="/seputar-aplikasi-android/membuat-huruf-atau-tulisan-pada-whatsapp-menjadi-monospace" component={MembuatHurufAtauTulisanPadaWhatsAppMenjadiMonospace}/>
                        <Route exact path="/seputar-aplikasi-android/tutorial-kirim-pesan-whatsapp-tanpa-simpan-nomor" component={TutorialKirimPesanWhatsAppTanpaSimpanNomor}/>
                        <Route exact path="/seputar-aplikasi-android/cara-mengetahui-pesan-sudah-terbaca-walaupun-tanda-terbaca-dinonaktifkan-penerima" component={CaraMengetahuiPesanSudahTerbacaWalaupunTandaTerbacaDinonaktifkanPenerima}/>
                        <Route exact path="/seputar-aplikasi-android/cara-menghemat-kuota-internet-saat-menggunakan-aplikasi-zoom" component={CaraMenghematKuotaInternetSaatMenggunakanAplikasiZoom}/>
                        <Route exact path="/seputar-aplikasi-android/cara-cek-kuota-internet" component={CaraCekKuotaInternet}/>
                        
                        <Route exact path="/pengetahuan-umum/kode-transfer-bank-di-indonesia" component={KodeTransferBankDiIndonesia}/>
                        <Route exact path="/pengetahuan-umum/jurnal-kerja" component={JurnalKerja}/>

                           

                      </Router>
                                      
                  </Switch>
                        
                </Router>
                

              </Switch>

              

                {/* <div class="gcse-searchbox-only"></div>  <!-- dua kolom --> */}
                {/* <div class="gcse-searchbox-only"></div>    <!-- dua halaman -->*/}
                {/* <div className="gcse-search"></div> <!--  kompak --> */}  
                {/*<div class="gcse-searchbox-only"></div>    google dihosting   */}
                {/*///////<div className="gcse-search"></div>  lebar penuh*/}
                {/*<div class="gcse-searchresults-only"></div>   hasil saja */}


            </div>

            <PopularPost/>            
            <Tentang/>            
            <Footer/>
          
          </div>
        </div>
      </Router>
      </Suspense>
      </ErrorBoundary>

     

    );
  }
  
}
export default  App;
