import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


////////
// import {render, hydrate} from "react-dom"

// window.addEventListener("beforeunload", () => {
    // Production code would also be considerate of localStorage size limitations
    // and would do a LRU cache eviction to maintain sanity on storage.
    // There should also be a way to clear this data when the user signs out
//     window.localStorage.setItem(
//       `lastKnown_${window.location.href}`,
//       JSON.stringify({
//         conditions: {
//           userId: "<dpp>",
//           buildNo: "<2.1>"
//         },
//         data: document.getElementById("root").innerHTML
//       })
//     );
//   });

// if (window.hasRestoredState) {
//   hydrate(<App />, document.getElementById('root'));
// } else {
//   render(<App />, document.getElementById('root'));
// }
//////////

///////////////
// ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
//////////////



// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <div>
      <App />
     
    </div>,
    document.getElementById('root')); 
    // bentuk lain dari => ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
