import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Typography
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";


function refreshPage(){
  setTimeout(()=>{
    window.location.reload(false);
  }, 500);
 
}

const Breadcrumbs = props => {
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.split("/").filter(x => x);
  return (
    <section className="colorlib-about">
    <br/>
    <div style={{backgroundColor: "white"}} className="colorlib-narrow-content">
  <div  className="row">
    <div className="col-md-12 col-md-offset-3 col-md-pull-3"  >
    
        <MUIBreadcrumbs aria-label="breadcrumb" >
        {pathnames.length > 0 ? (
            <Link onClick={ () => {
            history.push("/");
            refreshPage();
            }}><small>Home</small></Link>
        ) : (
            <Typography><small></small> </Typography>
        )}
        {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
            <Typography key={name}>{name}</Typography>
            ) : (
            <Link key={name} onClick={() => {
            history.push(routeTo);
            refreshPage();
            }}>
            <small>{name}</small>
            </Link>
            );
        })}
        </MUIBreadcrumbs>
        
        </div>
    </div>
    </div>
    </section>
  );
};

export default withRouter(Breadcrumbs);
