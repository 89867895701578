import React, { Component } from 'react'
// import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';

function refreshPage(){
    setTimeout(()=>{
      window.location.reload(false);
    }, 500);
   
  }

export default class DictsInfo extends Component {
  render() {
    return (
      <div>

        {/* <Helmet>
          <title>Dicts Info</title>
          <meta name="description" content="belajar Microsoft Office Excel 2010, tutorial Microsoft Office Excel 2010, tutorial Microsoft Excel 2010 untuk pemula, berapa bagian Microsoft Excel"/>
        </Helmet> */}
      
      
            
            {/* <div className="col-md-12 col-md-offset-0 col-md-pull-0" style={{WebkitTextFillColor:'white', backgroundColor: '#212529'}} > */}
            <div className="col-md-12 col-md-offset-0 col-md-pull-0" style={{WebkitTextFillColor:'white', backgroundColor: 'grey'}} >
                <h2 style={{fontSize: '1.5rem', fontFamily: 'Cataneo BT'}} 
                className="colorlib-heading text-center">Top 10 Popular Posts:</h2>
                <hr/>
                
                
                <div className= "w3-container" > 
                    <ol> 
                        <li>
                            <Link to="/unity-3D/cara-install-unity-3D-menggunakan-unity-hub" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Cara Instal Unity 3D Menggunakan Unity Hub</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/unity-3D/cara-install-unity-hub-dan-mendapatkan-lisensi-gratis-unity-3D" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Cara Instal Unity Hub Dan Mendapatkan Lisensi Gratis Unity 3D</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/unity-3D/cara-membuat-game-android-menggunakan-unity-3D-untuk-pemula-hello-world" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Cara Membuat Project baru Game Android Dengan Unity 3D - Membuat Hello World</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/unity-3D/cara-mengganti-kode-editor-yang-akan-digunakan-pada-unity-3D" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Cara Mengganti Kode Editor Yang Akan Digunakan Pada Unity 3D</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/microsoft-office-excel-2010/mengenal-fungsi-operasi-untuk-statistik-pada-ms-office-excel-2010" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Mengenal Fungsi Operasi Untuk Statistik Pada Microsoft Office Excel 2010</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/seputar-aplikasi-android/membuat-huruf-atau-tulisan-pada-whatsapp-menjadi-coretan" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Cara Membuat Huruf Atau Tulisan Di WhatsApp Menjadi Coretan Atau Tercoret</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/codeIgniter-4/instalasi_ci4" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Cara Instal CodeIgniter 4 Menggunakan Composer</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/corel-draw/mengubah-pengaturan-startup-corel-draw-x6" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Mengubah pengaturan Startup Corel Draw X6</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/corel-draw/mengenal-bagian-bagian-corel-draw-x6" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Mengenal Bagian Bagian Corel Draw X6</p>
                            </Link>
                        </li> 
                        <li>
                            <Link to="/pengetahuan-umum/kode-transfer-bank-di-indonesia" className="nav-link" onClick={refreshPage}>
                                <p style={{WebkitTextFillColor:'white'}}>Kode Transfer Bank Di Indonesia</p>
                            </Link>
                        </li> 
                    </ol> 
                </div>
            </div>

              
        
      </div>
    )
  }
}
